import React, { PureComponent } from "react";
import { without, round } from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import {
  Table,
  Card,
  DatePicker,
  Dropdown,
  Button,
  Icon,
  Form,
  Row,
  Col,
  Input,
} from "antd";
import createForm from "@36node/redux-form-antd";
import styled from "styled-components";

import {
  formatSec,
  tableStateToMekongQuery,
  ymdhms,
  suffix,
  getPathname,
} from "../../lib";
import { stationSelectors } from "../../selectors";
import { pileActions } from "../../actions/pile";
import { makeMekongApiToTableSelector } from "../../selectors/api-to-table";
import FilterDropdown from "../../components/filter-dropdown";
import StatusSelect from "../../components/status-select";
import NsSelect from "../common/ns-select";
import VehicleLink from "../../components/vehicle-link";
import { PileStationSelect } from "../../components/station-select";
import { SourceNameI18N } from "../../constants";

const RangePicker = DatePicker.RangePicker;

const title = "充电历史记录列表";

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const formTailLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8, offset: 8 },
};

const SearchContainer = styled(Card)`
  margin-bottom: 24px !important;

  .ant-form-item {
    display: flex;
  }

  .ant-form-item-control-wrapper {
    flex: 1;
  }
`;

export const ChargeFullyStatus = {
  true: "满充",
  false: "未满充",
};

export const ReissueStatus = {
  true: "是",
  false: "否",
};

const chargeStartAtShowTime = {
  format: "HH:mm",
  defaultValue: [moment("08:00", "HH:mm"), moment("08:00", "HH:mm")],
};

const chargeStartAtStyle = {
  width: "100%",
};

const defaultQuery = {
  _sort: "-chargeEndAt",
  _expand: ["vehicle", "station"],
  abnormal: false,
};

const getElecByType = type => arr => {
  const elec = arr.reduce((acc, cur) => {
    if (cur.type === type) {
      return acc + cur.elec;
    }
    return acc;
  }, 0);
  return elec ? `${round(elec, 3)}kWh` : "--";
};
const getElecByTypeExport = type => arr => {
  const elec = arr.reduce((acc, cur) => {
    if (cur.type === type) {
      return acc + cur.elec;
    }
    return acc;
  }, 0);
  return round(elec, 3);
};
const isReissue = val => {
  return val ? "补发" : "--";
};
const isReissueFormat = val => {
  return val ? <span style={{ color: "red" }}>补发</span> : "--";
};

@createForm("CHARGE_ORDER_SEARCH_FORM")
class OrderListPage extends PureComponent {
  state = {
    hideColumns: ["connectorNo", "chargeSn"],
  };

  componentWillMount() {
    this.fetchChargeOrders({ _limit: 10, _offset: 0 });
  }

  componentWillUnmount() {
    this.props.dispatch(pileActions.listChargeOrders.clear());
  }

  fetchChargeOrders = (query = {}) => {
    if (query.chargeStartAt && query.chargeStartAt.length === 2) {
      this.props.dispatch(
        pileActions.listChargeOrders.request(
          {
            ...defaultQuery,
            chargeStartAt_gte: query.chargeStartAt[0].toISOString(),
            chargeStartAt_lte: query.chargeStartAt[1].toISOString(),
            ...query,
          },
          {}
        )
      );
    } else {
      this.props.dispatch(
        pileActions.listChargeOrders.request(
          {
            ...defaultQuery,
            ...query,
          },
          {}
        )
      );
    }
  };

  listChargeOrders = (pagination = {}, filters = {}, sort = {}) => {
    const { query } = this.props;
    const tableQuery = tableStateToMekongQuery(pagination, filters, sort);
    this.fetchChargeOrders({ ...query, ...tableQuery });
  };

  handleExport = () => {
    // filename
    const filename = `${title}-${moment().format("YYYY-MM-DD")}`;
    const { query } = this.props;

    this.props.dispatch(
      pileActions.chargeOrdersXlsx.export(
        {
          columns: TableColumns,
          fileName: filename,
          fileType: "xlsx",
          params: {
            ...query,
            _limit: 10000,
            _offset: 0,
          },
        },
        {}
      )
    );
  };

  handleSumbit = e => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.fetchChargeOrders({ _limit: 10, _offset: 0, ...values });
      }
    });
  };

  handleRest = () => {
    this.props.reset();
    this.fetchChargeOrders({ _limit: 10, _offset: 0 });
  };

  showedColumns() {
    const { hideColumns } = this.state;
    const allValues = TableColumns.map(i => i.key);
    return without(allValues, ...hideColumns);
  }

  onColumnsFilterChange = values => {
    const allValues = TableColumns.map(i => i.key);
    this.setState({
      hideColumns: without(allValues, ...values),
    });
  };

  renderTopActions = () => {
    return (
      <div className="tool-bar">
        <Dropdown
          overlay={
            <FilterDropdown
              value={this.showedColumns()}
              onChange={this.onColumnsFilterChange}
              items={TableColumns.map(c => ({ key: c.key, name: c.title }))}
            />
          }
          trigger={["click"]}
        >
          <Button>
            <Icon type="filter" /> 选择数据项
          </Button>
        </Dropdown>
        <Button
          onClick={this.handleExport}
          type="primary"
          style={{ marginLeft: 10 }}
        >
          <Icon type="export" />
          导出
        </Button>
      </div>
    );
  };

  render() {
    const {
      docs = [],
      loading,
      total = 0,
      pagination,
      getFieldDecorator,
    } = this.props;
    const { hideColumns } = this.state;
    const showColumns = TableColumns.filter(c => !hideColumns.includes(c.key));

    const Title = () => (
      <div>
        {title}
        <span style={{ marginLeft: 10, fontSize: 12, fontWeight: 400 }}>
          共有 {total} 条记录
        </span>
      </div>
    );

    return (
      <React.Fragment>
        <SearchContainer>
          <Form onSubmit={this.handleSumbit}>
            <Row gutter={48} type="flex">
              <Col span={8}>
                <Form.Item label="部门" {...formItemLayout}>
                  {getFieldDecorator("ns_like", {})(<NsSelect allowClear />)}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="车辆自编号" {...formItemLayout}>
                  {getFieldDecorator("plate", {})(
                    <Input allowClear placeholder="请输入车辆自编号" />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="充电时间" {...formItemLayout}>
                  {getFieldDecorator("chargeStartAt", {})(
                    <RangePicker
                      placeholder={["起始日期", "结束日期"]}
                      format="MM-DD HH:mm"
                      showTime={chargeStartAtShowTime}
                      style={chargeStartAtStyle}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={48} type="flex">
              <Col span={8}>
                <Form.Item label="充电站" {...formItemLayout}>
                  {getFieldDecorator("station", {})(
                    <PileStationSelect allowClear />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="服务商平台" {...formItemLayout}>
                  {getFieldDecorator("source", {})(
                    <StatusSelect
                      allowClear
                      placeholder="请选择"
                      status={SourceNameI18N}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="充电枪自编码" {...formItemLayout}>
                  {getFieldDecorator("connectorNo", {})(
                    <Input allowClear placeholder="请输入充电枪自编码" />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={48} type="flex">
              <Col span={8}>
                <Form.Item label="是否满充" {...formItemLayout}>
                  {getFieldDecorator("isFull", {})(
                    <StatusSelect
                      allowClear
                      placeholder="请选择"
                      status={ChargeFullyStatus}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="是否补发" {...formItemLayout}>
                  {getFieldDecorator("isReissue", {})(
                    <StatusSelect
                      allowClear
                      placeholder="请选择"
                      status={ReissueStatus}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col offset={0} span={8}>
                <Form.Item {...formTailLayout}>
                  <Button onClick={this.handleRest}>重置</Button>
                  <Button
                    style={{ marginLeft: 24 }}
                    type="primary"
                    htmlType="submit"
                  >
                    筛选
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </SearchContainer>
        <Card
          bordered={false}
          title={<Title />}
          extra={this.renderTopActions()}
        >
          <Table
            rowKey="id"
            loading={loading}
            onChange={this.listChargeOrders}
            dataSource={docs}
            columns={showColumns}
            pagination={pagination}
            scroll={{ x: "max-content" }}
          />
        </Card>
      </React.Fragment>
    );
  }
}

export default connect((state, props) => {
  const selector = makeMekongApiToTableSelector(
    stationSelectors.listChargeOrders
  );
  const tableState = selector(state);

  return {
    ...tableState,
  };
})(OrderListPage);

export const TableColumns = [
  {
    title: "充电流水号",
    dataIndex: "chargeSn",
    key: "chargeSn",
  },
  {
    title: "车辆自编号",
    dataIndex: "plate",
    key: "plate",
    render: (val, record) => {
      return <VehicleLink title={val} vin={record.vin} />;
    },
  },
  {
    title: "VIN",
    dataIndex: "vin",
    key: "vin",
  },
  {
    title: "车辆厂家",
    dataIndex: "vehicle.manufacturer",
    key: "vehicle.manufacturer",
  },
  {
    title: "部门",
    dataIndex: "ns",
    key: "department",
    render: val => getPathname(val),
    exportFormat: val => getPathname(val),
  },
  { title: "线路", dataIndex: "line", key: "line" },
  {
    title: "充电站",
    dataIndex: "station.name",
    key: "station.name",
  },
  {
    title: "服务商平台",
    dataIndex: "source",
    key: "source",
    render: val => SourceNameI18N[val],
    exportFormat: val => SourceNameI18N[val],
  },
  {
    title: "充电枪自编码",
    dataIndex: "connectorNo",
    key: "connectorNo",
  },
  {
    title: "充电开始时间",
    dataIndex: "chargeStartAt",
    key: "chargeStartAt",
    render: ymdhms,
    sorter: true,
  },
  {
    title: "充电结束时间",
    dataIndex: "chargeEndAt",
    key: "chargeEndAt",
    render: ymdhms,
    sorter: true,
    defaultSortOrder: "descend",
  },
  {
    title: "收到数据时间",
    dataIndex: "updateAt",
    key: "updateAt",
    render: ymdhms,
  },
  {
    title: "充电时长",
    key: "duration",
    render: (_, record) =>
      formatSec(
        moment(record.chargeEndAt).diff(record.chargeStartAt, "seconds")
      ),
    exportFormat: (_, record) =>
      formatSec(
        moment(record.chargeEndAt).diff(record.chargeStartAt, "seconds")
      ),
  },
  {
    title: "开始 SoC",
    dataIndex: "startSoc",
    key: "startSoc",
    render: suffix("%"),
    sorter: true,
  },
  {
    title: "结束 SoC",
    dataIndex: "endSoc",
    key: "endSoc",
    render: suffix("%"),
    sorter: true,
  },
  {
    title: "尖时充电量",
    dataIndex: "details",
    key: "topPeak",
    render: getElecByType("topPeak"),
    exportFormat: getElecByTypeExport("topPeak"),
  },
  {
    title: "峰时充电量",
    dataIndex: "details",
    key: "peak",
    render: getElecByType("peak"),
    exportFormat: getElecByTypeExport("peak"),
  },
  {
    title: "平时充电量",
    dataIndex: "details",
    key: "flat",
    render: getElecByType("flat"),
    exportFormat: getElecByTypeExport("flat"),
  },
  {
    title: "谷时充电量",
    dataIndex: "details",
    key: "valley",
    render: getElecByType("valley"),
    exportFormat: getElecByTypeExport("valley"),
  },
  {
    title: "充电量",
    dataIndex: "elec",
    key: "elec",
    render: suffix("kWh"),
  },
  {
    title: "订单终止原因",
    dataIndex: ["stopReason", "name"],
    key: "stopReason",
  },
  {
    title: "是否补发",
    dataIndex: "isReissue",
    key: "isReissue",
    render: isReissueFormat,
    exportFormat: isReissue,
  },
];
