/**
 * 创建工单
 */

import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import qs from "qs";
import { get, isEmpty, trimStart } from "lodash";
import { connect } from "react-redux";
import styled from "styled-components";

import TicketInfo from "../../components/ticket/ticket-info";
import { TicketLayout } from "../../components/ticket/ticket-layout";
import TicketActions from "../../actions/tickets";
import { globalSelectors, TicketSelectors } from "../../selectors";
import AlertsTable from "../../components/ticket/alerts-table";
import HandleForm from "./handle-form";

@withRouter
@connect((state, props) => {
  const { location } = props;
  const query = qs.parse(trimStart(location.search, "?"));
  // const vehicle = TicketSelectors.getVehicle(state).result || {};
  // const department = getPathname(vehicle.ns);
  const alert = TicketSelectors.getAlert(state).result || {};
  const createLoading = TicketSelectors.createTicket(state).loading;
  const session = globalSelectors.session(state).result || { user: {} };

  const ticket = {
    // department,
    ns: alert.ns,
    device: alert.device,
    relatedFaults: [alert.id],
    stage: "创建工单",
    title: "新建工单",
  };

  return {
    ...query,
    ticket,
    alert,
    loading: false,
    createLoading,
    // vehicle,
    session,
  };
})
export default class CreateTicket extends PureComponent {
  componentDidMount() {
    const { alertId } = this.props;
    this.props.dispatch(TicketActions.initCreateTicket({ alertId }));
    this.props.dispatch(TicketActions.handleForm.reset());
  }

  renderTitle = () => {
    return <Title>创建工单</Title>;
  };

  onSubmit = (values, loginUser) => {
    const { alertId, alert, session } = this.props;
    const { title, desc, stage, by } = values;

    this.props.dispatch(
      TicketActions.createTicket.request(
        {
          body: {
            devices: [alert.device],
            line: alert.line,
            ns: alert.ns.id,
            relatedFaults: [alertId],
            stage,
            submitAt: alert.createAt,
            submitBy: get(session, "user.nickname"),
            title: `${get(alert, "device.no")}报警-${alert.name}`,
            type: "FAULT_HANDLING",
            handleResults: [
              {
                at: new Date(),
                by,
                desc,
                title,
                ns: get(session, "user.ns"),
                stage,
              },
            ],
          },
        },
        {
          successMsg: "工单创建成功",
        }
      )
    );
  };

  render() {
    const { ticket, loading, alert } = this.props;
    const alerts = isEmpty(alert) ? [] : [alert];

    return (
      <TicketLayout>
        <TicketInfo
          loading={loading}
          title={this.renderTitle()}
          ticket={ticket}
          alert={alert}
        />
        <Divider />
        <AlertsTable loading={loading} alerts={alerts} />
        <Divider />
        <HandleForm
          loading={this.props.createLoading}
          onSubmit={this.onSubmit}
          defaultStage="待维修"
          requireStage={true}
        />
      </TicketLayout>
    );
  }
}

const Title = styled.div`
  display: flex;
  align-items: center;

  padding: 12px 0;
  font-size: 20px;
  font-weight: 600;
`;

const Divider = styled.div`
  margin: 12px 0px;
`;
