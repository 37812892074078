import { isNil } from "lodash";
import { ConnectorStatus, ConnectorType } from "@36node-mekong/sdk-ts";

import { ymdhms } from "./lib";

/**
 * 登录锁定
 */
export const LOGIN_LOCK_DDL_KEY = "login_lock_ddl";

/**
 * 一些页面文字常量
 */
export const TEMPERATURE_UNIT = "℃";
export const RESISTANCE_UNIT = "kΩ";
export const PRESSURE_UNIT = "kPa";

export const SESSION_ID = "session_id";
export const TOKEN = "token";
export const ROLES = "roles";
export const SESSION_NS = "session_ns";
export const USER_ID = "user_id";
export const LOGIN_URL = "/login";

export const TERMINAL_NS = {
  DEBUG: {
    RECORD_FILTER_VALUES: "TERMINAL.DEBUG.RECORD_FILTER_VALUES",
    LIST_RECORDS: "TERMINAL.DEBUG.LIST_RECORDS",
    SELECT_ORIGIN_RECORD: "TERMINAL.DEBUG.SELECT_ORIGIN_RECORD",
  },
  MONITOR: {
    LIST_TERMINALS: "TERMINAL.MONITOR.LIST_TERMINALS",
    TERMINAL_FILTER_VALUES: "TERMINAL.MONITOR.TERMINAL_FILTER_VALUES",
    TERMINALS_XLSX: "TERMINAL.MONITOR.TERMINALS_XLSX",
    TERMINALS_OFFSITE_XLSX: "TERMINAL.MONITOR.TERMINALS_OFFSITE_XLSX",
    LIST_EXCEPTION: "TERMINAL.MONITOR.LIST_TERMINAL_EXCEPTION",
    EXCEPTIONS_XLSX: "TERMINAL.MONITOR.EXCEPTIONS_XLSX",
  },
};

export const HISTORY_NS = {
  ALERT: {
    // 报警监控
    FILTER_FORM: "HISTORY.ALERT.FILTER_FORM",
    LIST_ALERTS: "HISTORY.ALERT.LIST_ALERTS",
    LIST_ALERTS_CRON: "HISTORY.ALERT.LIST_ALERTS_CRON",
    LIST_USERS: "HISTORY.ALERT.LIST_USERS",
    WARNINGS_XLSX: "HISTORY.ALERT.WARNINGS_XLSX",
  },
  BATTERY: {
    // 电池异常
    FILTER_FORM: "HISTORY.BATTERY.FILTER_FORM",
    LIST_WARNINGS: "HISTORY.BATTERY.LIST_WARNINGS",
    WARNINGS_XLSX: "HISTORY.BATTERY.WARNINGS_XLSX",
  },
  INSULATION: {
    // 绝缘异常
    FILTER_FORM: "HISTORY.INSULATION.FILTER_FORM",
    LIST_WARNINGS: "HISTORY.INSULATION.LIST_WARNINGS",
    WARNINGS_XLSX: "HISTORY.INSULATION.WARNINGS_XLSX",
  },
  TYRE: {
    //轮胎异常
    FILTER_FORM: "HISTORY.TYRE.FILTER_FORM",
    LIST_WARNINGS: "HISTORY.TYRE.LIST_WARNINGS",
    WARNINGS_XLSX: "HISTORY.TYRE.WARNINGS_XLSX",
  },
  PILE: {
    //充电桩监控
    FILTER_FORM: "HISTORY.PILE.FILTER_FORM",
    LIST_WARNINGS: "HISTORY.PILE.LIST_WARNINGS",
    WARNINGS_XLSX: "HISTORY.PILE.WARNINGS_XLSX",
  },
  CHARGE_RECORDS: {
    // 充电记录
    FILTER_FORM: "HISTORY.CHARGE_RECORDS.FILTER_FORM",
    LIST_WARNINGS: "HISTORY.CHARGE_RECORDS.LIST_WARNINGS",
    WARNINGS_XLSX: "HISTORY.CHARGE_RECORDS.WARNINGS_XLSX",
  },
};

export const TICKETS_NS = {
  LIST_TICKETS: "TICKETS.LIST_TICKETS",
  LIST_VEHICLE_TICKETS: "TICKETS.LIST_VEHICLE_TICKETS",
  GET_TICKET: "TICKETS.GET_TICKET",
  CREATE_TICKET: "TICKETS.CREATE_TICKET",
  HANDLE_TICKET: "TICKETS.HANDLE_TICKET",
  CLOSE_TICKET: "TICKETS.CLOSE_TICKET",
  GET_VEHICLE: "TICKETS.GET_VEHICLE",
  GET_SNAPSHOT: "TICKETS.GET_SNAPSHOT",
  GET_USER: "TICKETS.GET_USER",
  LIST_ALERTS: "TICKETS.LIST_ALERTS",
  LIST_ALERTS_FOR_EXPORTS: "TICKETS.LIST_ALERTS_FOR_EXPORTS",
  IGNORE_ALERT: "TICKETS.IGNORE_ALERT",
  UPDATE_ALERT: "TICKETS.UPDATE_ALERT",
  LIST_STATGES: "TICKETS.LIST_STATGES",
  LIST_USERS: "TICKETS.LIST_USERS",
  LIST_EVENTS_USERS: "TICKETS.LIST_EVENTS_USERS",
  LIST_VEHICLES: "TICKETS.LIST_VEHICLES",
  LIST_SNAPSHOTS: "TICKETS.LIST_SNAPSHOTS",
  LIST_STAGE_TICKETS: "TICKETS.LIST_STAGE_TICKETS",
  LIST_STAGE_TICKETS_EXPORT: "TICKETS.LIST_STAGE_TICKETS_EXPORT",
  LIST_TICKETS_XLSX: "TICKETS.LIST_TICKETS_XLSX",
  LIST_TICKETS_FOR_EXPORT: "TICKETS.LIST_TICKETS_FOR_EXPORT",
  TICKETS_QUERY: "TICKETS.TICKETS_QUERY",
  FETCH_TICKETS: "TICKETS.FETCH_TICKETS",
  HANDLE_FORM: "TICKETS.HANDLE_FORM",
  STAGE_EVENT: "TICKETS.STAGE_EVENT",
  COMMENT_EVENT: "TICKETS.COMMENT_EVENT",
  BIND_EVENT: "TICKETS.BIND_EVENT",
  INIT_BIND_ALERT: "TICKETS.INIT_BIND_ALERT",
  GET_ALERT: "TICKETS.GET_ALERT",
  SELETED_TICKET: "TICKETS.SELETED_TICKET",
  LIST_UI_STATE: "TICKETS.LIST_UI_STATE",
  INIT_CREATE_TICKET: "TICKETS.INIT_CREATE_TICKET",
  STATISTICS: "TICKETS.STATISTICS",
  STATISTICS_STAGE: "TICKETS.STATISTICS_STAGE",
};

export const ANALYSIS_NS = {
  GET_ALERT_ANALYSIS: "ANALYSIS.GET_ALERT_ANALYSIS",
  GET_ALERT_RATE_ANALYSIS: "ANALYSIS.GET_ALERT_RATE_ANALYSIS",
  GET_ALL_ALERTS_ANALYSIS: "ANALYSIS.GET_ALL_ALERTS_ANALYSIS",
  AGG_FAULT_STATUS: "ANALYSIS.AGG_FAULT_STATUS",
  AGG_FAULT_DATE: "ANALYSIS.AGG_FAULT_DATE",
  AGG_FAULT_TYPE: "ANALYSIS.AGG_FAULT_TYPE",
  AGG_FAULT_NAME: "ANALYSIS.AGG_FAULT_NAME",
  AGG_FAULT_MODEL: "ANALYSIS.AGG_FAULT_MODEL",
  AGG_FAULT_NS: "ANALYSIS.AGG_FAULT_NS",
  AGG_FAULT_LEVEL: "ANALYSIS.AGG_FAULT_LEVEL",
  AGG_FAULT_RATE_NS: "ANALYSIS.AGG_FAULT_RATE_NS",
  AGG_FAULT_RATE_MODEL: "ANALYSIS.AGG_FAULT_RATE_MODEL",
  AGG_OPEN_TICKET_STAGE: "ANALYSIS.AGG_OPEN_TICKET_STAGE",
  AGG_ONSITE_VEHICLE: "ANALYSIS.AGG_ONSITE_VEHICLE",
  AGG_ORDER_NS: "ANALYSIS.AGG_ORDER_NS",
  AGG_ORDER_IS_FULL: "ANALYSIS.AGG_ORDER_IS_FULL",
  AGG_ORDER_STATION: "ANALYSIS.AGG_ORDER_STATION",
  AGG_STOP_ORDER_STATION: "ANALYSIS.AGG_STOP_ORDER_STATION",
  AGG_STOP_ORDER_SOURCE: "ANALYSIS.AGG_STOP_ORDER_SOURCE",
  AGG_STOP_ORDER_NAME: "ANALYSIS.AGG_STOP_ORDER_NAME",
};

export const BANCI_NS = {
  LIST_BANCI: "BANCI.LIST_BANCI",
  FILTER_VALUES: "BANCI.FILTER_VALUES",
  BANCI_XLSX: "BANCI.BANCI_XLSX",
};

export const NS = {
  TICKET: TICKETS_NS,
  OVERVIEW: {
    GET_VEHICLE_STATS: "OVERVIEW.GET_VEHICLE_STATS",
    LIST_CHART_ALERTS: "OVERVIEW.LIST_CHART_ALERTS",
    GET_TYRE_SUMMARY: "OVERVIEW.GET_TYRE_SUMMARY",
    GET_BATTERY_SUMMARY: "OVERVIEW.GET_BATTERY_SUMMARY",
    GET_ALERT_SUMMARY: "OVERVIEW.GET_ALERT_SUMMARY",
    LIST_OFFLINE_TERMINALS: "OVERVIEW.LIST_OFFLINE_TERMINALS",
    GET_TODAY_ALERTS_LV1: "OVERVIEW.GET_TODAY_ALERTS_LV1",
    GET_TODAY_ALERTS_LV2: "OVERVIEW.GET_TODAY_ALERTS_LV2",
    GET_TODAY_ALERTS_LV3: "OVERVIEW.GET_TODAY_ALERTS_LV3",
    AGG_ONSITE_VEHICLE: "OVERVIEW.AGG_ONSITE_VEHICLE",
    AGG_ONLINE_VEHICLE: "OVERVIEW.AGG_ONLINE_VEHICLE",
    AGG_ONLINE_TODAY_VEHICLE: "OVERVIEW.AGG_ONLINE_TODAY_VEHICLE",
    ORDER_TODAY: "OVERVIEW.ORDER_TODAY",
    AGG_ALERT_TODAY: "OVERVIEW.AGG_ALERT_TODAY",
    AGG_ALERT_30_LEVEL: "OVERVIEW.AGG_ALERT_30_LEVEL",
    AGG_ALERT_30_NAME: "OVERVIEW.AGG_ALERT_30_NAME",
    AGG_ALERT_30_MODEL: "OVERVIEW.AGG_ALERT_30_MODEL",
    AGG_TICKET_TODAY: "OVERVIEW.AGG_TICKET_TODAY",
    AGG_TICKET_30: "OVERVIEW.AGG_TICKET_30",
    AGG_BATTERY_WARNING: "OVERVIEW.AGG_BATTERY_WARNING",
    AGG_TYRE_WARNING: "OVERVIEW.AGG_TYRE_WARNING",
    SUMMARY_CRON: "SUMMARY_CRON",
  },
  HISTORY: HISTORY_NS,
  REPORT: {
    GET_WARNING_STATS: "REPORT.GET_WARNING_STATS",
    GET_OVERALL_WARNING_STATS: "REPORT.GET_OVERALL_WARNING_STATS",
    EXPORT_WARNING_STATS: "REPORT.EXPORT_WARNING_STATS",
    EXPORT_WARNING_OVERALL_STATS: "REPORT.EXPORT_WARNING_OVERALL_STATS",
    GET_VEHICLE_STATS: "REPORT.GET_VEHICLE_STATS",
    EXPORT_VEHICLE_STATS: "REPORT.EXPORT_VEHICLE_STATS",
    LIST_TABLE_ALERTS: "REPORT.LIST_TABLE_ALERTS",
    GET_TICKET_STATS: "REPORT.GET_TICKET_STATS",
    GET_OVERALL_TICKET_STATS: "REPORT.GET_OVERALL_TICKET_STATS",
    EXPORT_TICKET_STATS: "REPORT.EXPORT_TICKET_STATS",
    EXPORT_TICKET_OVERALL_STATS: "REPORT.EXPORT_TICKET_OVERALL_STATS",
    GET_OVERALL_POWER_STATS: "REPORT.GET_OVERALL_POWER_STATS",
    EXPORT_POWER_OVERALL_STATS: "REPORT.EXPORT_POWER_OVERALL_STATS",
    GET_CHARGE_ORDER_AGG: "GET_CHARGE_ORDER_AGG",
  },
  ARCHIVES: {
    LIET_VEHICLES: "ARVHIVES.LIET_VEHICLES",
    LIST_SNAPSHOTS: "ARVHIVES.LIST_SNAPSHOTS",
    VEHICLES_XLSX: "ARVHIVES.VEHICLES_XLSX",
    CREATE_VEHICLE: "ARVHIVES.CREATE_VEHICLE",
    UPSERT_VEHICLE: "ARVHIVES.UPSERT_VEHICLE",
    DELETE_VEHICLE: "ARVHIVES.DELETE_VEHICLE",
    DELETE_ARCHIVES: "ARCHIVES.DELETE_ARCHIVES",
    UPDATE_VEHICLE: "ARCHIVES.UPDATE_VEHICLE",
    EDITOR_FORM_NS: "ARCHIVES.EDITOR_FORM_NS",
  },
  MONITOR: {
    ALERT: {
      LIST_ALERTS: "MONITOR.ALERT.LIST_ALERTS",
      LIST_OPEN_TICKETS: "MONITOR.ALERT.LIST_OPEN_TICKETS",
      EXPORT_ALERTS: "MONITOR.ALERT.EXPORT_ALERTS",
    },
    WARNING: {
      LIST_WARNINGS: "MONITOR.WARNING.LIST_WARNINGS",
      EXPORT_WARNINGS: "MONITOR.WARNING.EXPORT_WARNINGS",
    },
    PILE: {
      LIST_STATIONS: "MONITOR.PILE.LIST_STATIONS",
      LIST_ALL_STATIONS: "MONITOR.PILE.LIST_ALL_STATIONS",
      GET_STATION: "MONITOR.PILE.GET_STATION",
      UPDATE_STATION: "MONITOR.PILE.UPDATE_STATION",
      DELETE_STATION: "MONITOR.PILE.DELETE_STATION",
      GET_PILE_STATS: "MONITOR.PILE.GET_PILE_STATS",
      LIST_PILES: "MONITOR.PILE.LIST_PILES",
      LIST_CONNECTORS: "MONITOR.PILE.LIST_CONNECTORS",
      UPDATE_CONNECTOR: "MONITOR.PILE.UPDATE_CONNECTOR",
      LIST_ORDERS: "MONITOR.PILE.LIST_ORDERS",
      PILES_XLSX: "MONITOR.PILE.PILES_XLSX",
      CONNECTORS_XLSX: "MONITOR.PILE.CONNECTORS_XLSX",
      ORDERS_XLSX: "MONITOR.PILE.ORDERS_XLSX",
      STATION_ORDERS_XLSX: "MONITOR.PILE.ORDERS_CACHE_XLSX",
      DELETE_CONNECTORS: "MONITOR.PILE.DELETE_CONNECTORS",
      DELETE_CONNECTOR: "MONITOR.PILE.DELETE_CONNECTOR",
      LIST_VEHICLES: "MONITOR.PILE.LIST_VEHICLES",
    },
    STATISTICS: {
      GET_ALERT_STATS: "MONITOR.STATISTICS.GET_ALERT_STATS",
      GET_WARNING_STATS: "MONITOR.STATISTICS.WARNING_STATS",
    },
    MAP: {
      SELECTED_VEHICLE: "MONITOR.MAP.SELECTED_VEHICLE",
    },
    GET_VEHICLE: "MONITOR.GET_VEHICLE",
    GET_VEHICLE_STATE: "MONITOR.GET_VEHICLE_STATE",
    GET_VEHICLE_CRON: "MONITOR.GET_VEHICLE_CRON",
    GET_SNAPSHOT: "MONITOR.GET_SNAPSHOT",
    LIST_TBOX_RECORDS: "MONITOR.LIST_TBOX_RECORDS",
    LIST_TBOX_HISTORY: "MONITOR.LIST_TBOX_HISTORY",
    LIST_RECORDS: "MONITOR.LIST_RECORDS",
    LIST_RECORDS_GRAPH: "MONITOR.LIST_RECORDS_GRAPH",
    RECORD_GRAPH_FILTER: "MONITOR.RECORD_GRAPH_FILTER",
    LIST_VEHICLES: "MONITOR.LIST_VEHICLES",
    LIST_VEHICLES_CRON: "MONITOR.LIST_VEHICLES_CRON",
    LIST_ALERTS: "MONITOR.LIST_ALERTS",
    LIST_USERS: "MONITOR.LIST_USERS",
    LIST_TABLE_ALERTS: "MONITOR.TABLE.LIST_ALERTS",
    LIST_STATION_TABLE_ALERTS: "MONITOR.TABLE.LIST_STATION_ALERTS",
    LIST_TABLE_ALERTS_CRON: "MONITOR.TABLE.LIST_ALERTS_CRON",
    LIST_VEHICLE_ALERTS: "MONITOR.VEHICLE.LIST_ALERTS",
    LIST_VEHICLE_MILEAGES: "MONITOR.LIST_VEHICLE_MILEAGES",
    LIST_VEHICLE_SNAPSHOTS: "MONITOR.LIST_VEHICLE_SNAPSHOTS",
    LIST_VEHICLE_ENERGYCOMSUMPTIONS: "MONITOR.LIST_VEHICLE_ENERGYCOMSUMPTIONS",
    GET_ALERT_SUMMARY: "MONITOR.GET_ALERT_SUMMARY",
    GET_TICKETS_STATS: "MONITOR.GET_TICKETS_STATS",
    MAP_DATA_CRON: "MONITOR.MAP_DATA_CRON",
    MAP_STATE: "MONITOR.MAP_STATE",
    VEHICLE_STATE: "MONITOR.ALERT.VEHICLE_STATE",
    CHARGING_LIST_TAB: "MONITOR.CHARGING_LIST_TAB",
    CHARGING_VEHICLES_XLSX: "MONITOR.CHARGING_VEHICLES_XLSX",
    VEHICLE_MILEAGES_XLSX: "MONITOR.VEHICLE_MILEAGES_XLSX",
    VEHICLE_ENERGYCOMSUMPTIONS_XLSX: "MONITOR.VEHICLE_ENERGYCOMSUMPTIONS_XLSX",
    UPDATE_SNAP: "MONITOR.UPDATE_SNAP",
    CREATE_SNAP: "MONITOR.CREATE_SNAP",
  },
  AGGREGATE: {
    ORDER_CHARGER_PER_HOUR: "ORDER.CHARGER.PER.DAY",
  },
  VEHICLE: {
    GET_VEHICLE: "VEHICLE.GET_VEHICLE",
    LIST_VEHICLES: "VEHICLE.LIST_VEHICLES",
    LIST_USERS: "VEHICLE.LIST_USERS",
    LIST_TICKTS: "VEHICLE.LIST_TICKETS",
    LIST_STAGES: "VEHICLE.LIST_STAGES",
    GET_STATISTICS: "VEHICLE.GET_STATISTICS",
    GET_STATISTICS_CRON: "VEHICLE.GET_STATISTICS_CRON",
    LIST_FAULTS: "VEHICLE.LIST_FAULTS",
    GET_STATE_HISTORY: "VEHICLE.GET_STATE_HISTORY",
    STATE_HISTORY_XLSX: "STATE_HISTORY_XLSX",
  },
  GLOBAL: {
    REFRESH: "GLOBAL.REFRESH",
    LOGIN: "GLOBAL.LOGIN",
    LOGOUT: "GLOBAL.LOGOUT",
    GET_CAPTCHA: "GLOBAL.GET_CAPTCHA",
    LIST_VEHICLES: "GLOBAL.LIST_VEHICLES",
    LIST_ALERTS: "GLOBAL.LIST_ALERTS",
    LIST_LINES: "GLOBAL.LIST_LINES",
    LIST_PRODUCERS: "GLOBAL.LIST_PRODUCERS",
    LIST_NS: "GLOBAL.LIST_NS",
    LIST_NAMESPACES: "GLOBAL.LIST_NAMESPACES",
    LIST_USERS: "GLOBAL.LIST_USERS",
    CHANGE_TREE_KEYWORD: "GLOBAL.CHANGE_TREE_KEYWORD",
    TOGGLE_FOCUS_SEARCH: "GLOBAL.TOGGLE_FOCUS_SEARCH",
    GET_WEATHER: "GLOBAL.GET_WEATHER",
    GET_ALERT_SUMMARY: "GLOBAL.GET_ALERT_SUMMARY",
    GET_STATION_ALERT_SUMMARY: "GLOBAL.GET_STATION_ALERT_SUMMARY",
    ALERT_SUMMARY_CRON: "GLOBAL.ALERT_SUMMARY_CRON",
    LIST_VEHICLES_CRON: "GLOBAL.LIST_VEHICLES_CRON",
    LOGIN_COUNT_DOWN: "GLOBAL.LOGIN_COUNT_DOWN",
  },
  SYSTEM: {
    LIST_NAMESPACES: "SYSTEM.LIST_NAMESPACES",
    CREATE_NAMESPACE: "SYSTEM.CREATE_NAMESPACE",
    GET_NAMESPACE: "SYSTEM.GET_NAMESPACE",
    UPDATE_NAMESPACE: "SYSTEM.UPDATE_NAMESPACE",
    DELETE_NAMESPACE: "SYSTEM.DELETE_NAMESPACE",
    LIST_USERS: "SYSTEM.LIST_USERS",
    LIST_CHARGER_OPERATORS: "SYSTEM.LIST_CHARGER_OPERATORS",
    CREATE_USER: "SYSTEM.CREATE_USER",
    GET_USER: "SYSTEM.GET_USER",
    UPDATE_USER: "SYSTEM.UPDATE_USER",
    DELETE_USER: "SYSTEM.DELETE_USER",
    LIST_REALTIME_LOGS: "SYSTEM.LIST_REALTIME_LOGS",
    LIST_HISTORY_LOGS: "SYSTEM.LIST_HISTORY_LOGS",
    LIST_TEMPLATES: "SYSTEM.LIST_TEMPLATES",
    CREATE_TEMPLATE: "SYSTEM.CREATE_TEMPLATE",
    GET_TEMPLATE: "SYSTEM.GET_TEMPLATE",
    UPDATE_TEMPLATE: "SYSTEM.UPDATE_TEMPLATE",
    DELETE_TEMPLATE: "SYSTEM.DELETE_TEMPLATE",
    LIST_OPERATIONS: "SYSTEM.LIST_OPERATIONS",
  },
  TERMINAL: TERMINAL_NS,
  ANALYSIS: ANALYSIS_NS,
  BANCI: BANCI_NS,
};

/**
 * 轮胎
 */
export const Tires = [
  "lf", // 左前轮
  "rf", // 右前轮
  "lr1", // 左后轮1
  "lr2", // 左后轮2
  "rr1", // 右后轮1
  "rr2", // 右后轮2
];

export const TiresI18n = {
  lf: "左前轮", // 左前轮
  rf: "右前轮", // 右前轮
  lr1: "左后轮1", // 左后轮1
  lr2: "左后轮2", // 左后轮2
  rr1: "右后轮1", // 右后轮1
  rr2: "右后轮2", // 右后轮2
};

export const RENDER = {
  tempWithUnit: val => (!isNil(val) ? `${val}${TEMPERATURE_UNIT}` : "--"),
  pressureWithUnit: val => (!isNil(val) ? `${val}${PRESSURE_UNIT}` : "--"),
  date: val => val && ymdhms(val),
};

export const AlertLevel = {
  3: "三级报警",
  2: "二级报警",
  1: "一级报警",
};

/**
 * 用户角色
 */
export const Roles = {
  CALT_MONITOR: "CALT_MONITOR",
  PRODUCER_MONITOR: "PRODUCER_MONITOR",
  OPERATION_MONITOR: "OPERATION_MONITOR",
  MAINTENANCE_MONITOR: "MAINTENANCE_MONITOR",
  ADMIN: "ADMIN",
  TERMINAL_OP: "TERMINAL_OP",
  AUDIT_ADMIN: "AUDIT_ADMIN", // 审计用户
  USERMANAGER: "USERMANAGER", // 用户管理员
  PILES_MONITOR: "PILES_MONITOR", // 充电桩监控人员
};

/**
 * 用户角色翻译
 */
export const RolesI18n = {
  CALT_MONITOR: "CATL监控人员",
  PRODUCER_MONITOR: "整车厂监控人员",
  OPERATION_MONITOR: "营运公司监控人员",
  MAINTENANCE_MONITOR: "机务保障中心监控人员",
  ADMIN: "系统管理员",
  TERMINAL_OP: "终端运维",
  AUDIT_ADMIN: "审计管理员", // 审计用户
  USERMANAGER: "用户管理员", // 用户管理员
  PILES_MONITOR: "充电桩监控人员",
};

/**
 * 空调模式: 关闭, 进风, 制热, 制冷
 */
export const AirMode = {
  OFF: "关闭",
  WIND: "进风",
  HEATING: "制热",
  REFRIGERATION: "制冷",
  ABNORMAL: "异常",
};

/**
 * 门状态: 关闭, 开, 异常
 */
export const DoorStatus = ["CLOSE", "OPEN", "ABNORMAL"];

/**
 * 手刹状态: 松, 刹, 异常
 */
export const HandbrakeStatus = ["OFF", "ON", "ABNORMAL"];

/**
 * 钥匙位置
 */
export const KeyStatus = ["OFF", "ACC", "ON", "START"];

/**
=======
 * 充电状态: 车辆的充电状态: 停车充电、行驶充电、未充电、充电完成
 */
export const ChargeStatus = {
  PARK_CHARGING: "PARK_CHARGING",
  MOVE_CHARGING: "MOVE_CHARGING",
  UNCHARGED: "UNCHARGED",
  CHARGED: "CHARGED",
  ABNORMAL: "ABNORMAL",
};

export const ExceptionTypes = {
  ALERT_EXCEPTION: "ALERT_EXCEPTION", // 报警监控
  BATTERY_EXCEPTION: "BATTERY_WARNING", // 电池异常
  INSULATION_EXCEPTION: "RESISTANCE_WARNING", // 绝缘异常
  TYRE_EXCEPTION: "TYRE_WARNING", // 轮胎异常
  PILE_EXCEPTION: "PILE_EXCEPTION", // 充电桩监控
  TBOX_EXCEPTION: "TBOX_WARNING", // 充电桩监控
};

/**
 * 车辆终端状态: 未知（从未登录）, 在线， 离线, 异常
 */
export const VehicleTermState = {
  // UNKNOWN: "UNKNOWN",
  ONLINE: "ONLINE",
  OFFLINE: "OFFLINE",
  // ABNORMAL: "ABNORMAL", // 异常
};

/**
 * 车辆终端状态 翻译
 */
export const VehicleTermStateI18N = {
  ONLINE: "在线",
  OFFLINE: "离线",
  UNKNOWN: "未接入",
  ABNORMAL: "异常", // 异常
};

export const DCStatusI18N = {
  ON: "开",
  OFF: "关",
  ABNORMAL: "异常",
};

export const DoorStatusI18N = {
  CLOSE: "关闭",
  OPEN: "开启",
  ABNORMAL: "异常",
};
export const BreakStatusI18N = {
  ON: "开",
  OFF: "关",
  ABNORMAL: "异常",
};
export const AirmodeStatusI18N = {
  WIND: "进风",
  OFF: "关",
  REFRIGERATION: "制冷",
  HEATING: "制热",
  ABNORMAL: "异常",
};

export const ChargeStatusI18N = {
  PARK_CHARGING: "停车充电",
  MOVE_CHARGING: "行驶充电",
  UNCHARGED: "未充电状态",
  CHARGED: "充电完成",
  ABNORMAL: "异常",
};

export const VehicleStatus = {
  ON: "ON",
  OFF: "OFF",
  OTHER: "OTHER",
};

// 车辆发动机状态
export const VehicleStatusI18N = {
  ON: "启动",
  OFF: "熄火",
  OTHER: "其他",
  ABNORMAL: "异常",
};

export const VehicleStatusColor = {
  ON: "#52C41A",
  OFF: "#919191",
  OTHER: "#13C2C2",
  ABNORMAL: "#D46B08",
};

/**
 * @readonly
 * @constant
 * 车辆运营状态
 */
export const VehicleRunState = {
  CHARGING: "CHARGING", // 充电中
  RUNNING: "RUNNING", // 运行中
  STOP: "STOP", // 停驶中
  OFFLINE: "OFFLINE", // 离线
  UNKNOWN: "UNKNOWN", // 未接入
};

/**
 * @readonly
 * @constant
 * 车辆运营状态翻译
 */
export const VehicleRunStateI18N = {
  CHARGING: "充电中", // 充电中
  RUNNING: "运行中", // 运行中
  STOP: "停驶中", // 停驶中
  OFFLINE: "离线", // 离线
  UNKNOWN: "无数据",
};

/**
 * @readonly
 * @constant
 * 车辆运营状态颜色
 */
export const VehicleRunStateColor = {
  CHARGING: "rgb(64, 169, 255)", // 充电中
  RUNNING: "#52C41A", // 运行中
  STOP: "#919191", // 停驶中
  OFFLINE: "#919191", // 离线
  UNKNOWN: "#919191", // 未接入
};

export const MotorStatusI18N = {
  CONSUMPTION: "耗能",
  GENERATION: "发电",
  OFF: "关",
  READY: "就绪",
  ABNORMAL: "异常",
};

export const DrivingExceptionI18N = {
  超速: "超速",
  紧急制动: "急刹车",
  大电流放电: "大电流放电",
};

export const BatteryExceptionI18N = {
  BATTERY_TEMP_OVER_55: "电池高温报警(超过55℃)",
  BATTERY_TEMP_OVER_45: "电池高温预警(45-55℃)",
  SOC_BELOW_20: "电池SoC告急(低于20%)",
  SOC_BELOW_30: "电池SoC预警(低于30%)",
};

export const BatteryExpI18N = {
  电池温度报警: "电池高温报警(超过55℃)",
  电池温度异常: "电池高温预警(45-55℃)",
  电池SoC预警: "电池SoC预警(低于30%)",
  电池SoC告急: "电池SoC告急(低于20%)",
};

export const InsulationExceptionI18N = {
  RESS_BELOW_1: "绝缘报警(小于1千欧)",
  RESS_BELOW_100: "绝缘预警(1千欧-100千欧)",
};

export const TBoxExceptionI18N = {
  TBOX_TIME_WRONG: "TBox时间错误",
};

export const AirExceptionI18N = {
  应开空调未开: "空调未开",
  空调性能不达标: "效果不好",
  不应开空调而开空调: "不应开",
};

export const TyreExceptionI18N = {
  TYRE_PRESSURE_LOW: "胎压过低",
  TYRE_PRESSURE_HIGH: "胎压过高",
  TYRE_TEMP_HIGH: "胎温过高",
};

export const EquipmentExceptionI18N = {
  终端离线: "终端离线",
  异常离线: "异常离线",
  数据缺失: "数据缺失",
};

export const InsulationExpI18N = {
  绝缘报警: "小于500欧",
  绝缘预警: "500欧-1兆欧",
  维保时绝缘报警: "维保时绝缘报警",
};

export const AlertStatusI18N = {
  BROKEN: "未恢复",
  RECOVER: "已恢复",
};

export const AlertStatus = {
  BROKEN: "BROKEN",
  RECOVER: "RECOVER",
};

export const AlarmActionI18N = {
  STOP_VEHICLE: "立即停驶",
  REPAIR_LATER: "返厂处理",
  CLOSE_ALARM: "关闭警报",
};

export const ChargeTypeI18N = {
  DC: "交流电",
  AC: "直流电",
};

export const PileTypeI18N = {
  DC: "直流设备",
  AC: "交流设备",
  AC_AND_DC: "交流直流一体设备",
  WIRELESS: "无线设备",
  OTHER: "其他",
};

export const ConnectorTypeI18N = {
  HOUSEHOLD_SOCKET: "家用插座",
  AC_INTERFACE_SOCKET: "交流接口插座",
  AC_INTERFACE_PLUG: "交流接口枪头",
  DC_INTERFACE_TIP: "直流接口枪头",
  WIRELESS_CHARGING_STAND: "无线充电座",
  OTHER: "其他",
};

// 充电枪状态
export const ConnectorStatusUiMap = {
  [ConnectorStatus.OFFLINE]: { label: "离线", color: "rgba(0,0,0,0.25)" },
  [ConnectorStatus.IDLE]: { label: "空闲", color: "#52C41A" },
  [ConnectorStatus.OCCUPIED_NOT_CHARGING]: {
    label: "占用",
    color: "#FFA610",
  },
  [ConnectorStatus.OCCUPIED_CHARGING]: { label: "充电中", color: "#008FFF" },
  [ConnectorStatus.OCCUPIED_ORDERED]: { label: "预约中", color: "#008FFF" },
  [ConnectorStatus.ERROR]: { label: "故障", color: "#F5222D" },
};

export const ConnectorTypeUiMap = {
  [ConnectorType.HOUSEHOLD_SOCKET]: { label: "家用插座" },
  [ConnectorType.AC_INTERFACE_SOCKET]: { label: "交流接口插座" },
  [ConnectorType.AC_INTERFACE_PLUG]: {
    label: "交流接口枪头",
  },
  [ConnectorType.DC_INTERFACE_TIP]: { label: "直流接口枪头" },
  [ConnectorType.WIRELESS_CHARGING_STAND]: { label: "无线充电座" },
  [ConnectorType.OTHER]: { label: "其他" },
};

export const SourceNameI18N = {
  "313744932": "星星充电",
  "76648183X": "惠充",
  "059746034": "科大智能",
  "733065928": "科华",
  "759588065": "上海一电",
  "395815801": "特来电",
  "088268094": "阳光乐充",
  MA1GB1F36: "玖行",
};

export const VehicleRecordSchema = [
  {
    name: "整车数据",
    key: "basic",
    data: [
      {
        name: "采集时间",
        key: "reportedAt",
        path: "at",
        recordPath: "at",
        format: val => ymdhms(val),
        type: "string",
        historyTable: {
          defaultShow: true,
          width: 190,
        },
      },
      {
        name: "车辆状态",
        key: "status",
        path: "overall.status", // 相对于Vehicle 结构的路径，可以通过lodash的get函数直接获取
        format: val => VehicleStatusI18N[val], // 翻译
        type: "string",
        historyTable: {
          // 在车辆历史记录表里面的配置, 如果没有，则不显示在车辆历史记录表中
          defaultShow: true,
        },
      },
      {
        name: "充电状态",
        key: "chargeStatus",
        path: "overall.chargeStatus",
        format: val => ChargeStatusI18N[val],
        type: "enum",
        historyTable: {
          defaultShow: true,
        },
      },
      {
        name: "车速",
        key: "speed",
        path: "overall.speed",
        unit: "km/h",
        type: "number",
        historyTable: {
          defaultShow: true,
          width: 120,
        },
        historyGraph: {
          show: true,
        },
      },
      {
        name: "里程",
        key: "mileage",
        path: "overall.mileage",
        recordPath: "overall.mileage",
        unit: "km",
        type: "number",
        historyTable: {
          defaultShow: true,
          width: 140,
        },
        historyGraph: {
          show: true,
        },
      },
      {
        name: "档位",
        key: "shift",
        path: "overall.shift",
        type: "string",
        historyTable: {
          defaultShow: true,
        },
      },
      {
        name: "车辆vin",
        key: "vin",
        path: "vin",
        type: "string",
      },
      {
        name: "钥匙位置",
        key: "keyPosition",
        path: "customExt.keyPosition",
        type: "string",
        historyTable: {},
      },
      {
        name: "系统绝缘阻抗",
        key: "resistance",
        path: "overall.resistance",
        unit: "KΩ",
        type: "number",
        historyTable: {
          defaultShow: true,
          width: 180,
        },
        historyGraph: {
          show: true,
        },
      },
      {
        name: "GPS经纬度",
        key: "location",
        path: "location",
        format: location => {
          if (location)
            return `${location.lng.toFixed(2)}N,${location.lat.toFixed(2)}E`;
          return "暂无数据";
        },
        type: "object",
        historyTable: {
          width: 120,
        },
      },
      {
        name: "加速踏板状态(行程值)",
        key: "aptv",
        path: "overall.aptv",
        format: val => {
          return (Number(val) * 100).toFixed(2);
        },
        unit: "%",
        type: "number",
        historyTable: {
          defaultShow: true,
          width: 120,
        },
        historyGraph: {
          show: true,
        },
      },
      {
        name: "制动踏板状态(行程值)",
        key: "brake",
        path: "overall.brake",
        format: val => {
          return (Number(val) * 100).toFixed(2);
        },
        unit: "%",
        type: "number",
        historyTable: {
          defaultShow: true,
          width: 120,
        },
        historyGraph: {
          show: true,
        },
      },
      {
        name: "气泵散热器温度",
        key: "apTemp",
        path: "customExt.apTemp",
        unit: "°C",
        type: "number",
        historyTable: {},
        historyGraph: {
          show: true,
        },
      },
      {
        name: "手刹信号",
        key: "handbrakeStatus",
        path: "customExt.handbrakeStatus",
        format: val => BreakStatusI18N[val],
        type: "string",
        historyTable: {},
      },
      {
        name: "前门状态",
        key: "frontDoorStatus",
        path: "customExt.frontDoorStatus",
        format: val => DoorStatusI18N[val],
        type: "string",
        historyTable: {},
      },
      {
        name: "中门状态",
        key: "middleDoorStatus",
        path: "customExt.middleDoorStatus",
        format: val => DoorStatusI18N[val],
        type: "string",
        historyTable: {},
      },
      {
        name: "左前轮胎压力",
        key: "lftp",
        path: "customExt.lftp",
        unit: "kPa", // 数据的单位
        type: "number",
        historyTable: {},
        historyGraph: {
          show: true,
        },
      },
      {
        name: "右前轮胎压力",
        key: "rftp",
        path: "customExt.rftp",
        unit: "kPa", // 数据的单位
        type: "number",
        historyTable: {},
        historyGraph: {
          show: true,
        },
      },
      {
        name: "左后1轮胎压力",
        key: "lr1tp",
        path: "customExt.lr1tp",
        unit: "kPa", // 数据的单位
        type: "number",
        historyTable: {},
        historyGraph: {
          show: true,
        },
      },
      {
        name: "左后2轮胎压力",
        key: "lr2tp",
        path: "customExt.lr2tp",
        unit: "kPa", // 数据的单位
        type: "number",
        historyTable: {},
        historyGraph: {
          show: true,
        },
      },
      {
        name: "右后1轮胎压力",
        key: "rr1tp",
        path: "customExt.rr1tp",
        unit: "kPa", // 数据的单位
        type: "number",
        historyTable: {},
        historyGraph: {
          show: true,
        },
      },
      {
        name: "右后2轮胎压力",
        key: "rr2tp",
        path: "customExt.rr2tp",
        unit: "kPa", // 数据的单位
        type: "number",
        historyTable: {},
        historyGraph: {
          show: true,
        },
      },
      {
        name: "左前轮胎温度",
        key: "lftt",
        path: "customExt.lftt",
        unit: "°C", // 数据的单位
        type: "number",
        historyTable: {},
        historyGraph: {
          show: true,
        },
      },
      {
        name: "右前轮胎温度",
        key: "rftt",
        path: "customExt.rftt",
        unit: "°C", // 数据的单位
        type: "number",
        historyTable: {},
        historyGraph: {
          show: true,
        },
      },
      {
        name: "左后1轮胎温度",
        key: "lr1tt",
        path: "customExt.lr1tt",
        unit: "°C", // 数据的单位
        type: "number",
        historyTable: {},
        historyGraph: {
          show: true,
        },
      },
      {
        name: "左后2轮胎温度",
        key: "lr2tt",
        path: "customExt.lr2tt",
        unit: "°C", // 数据的单位
        type: "number",
        historyTable: {},
        historyGraph: {
          show: true,
        },
      },
      {
        name: "右后1轮胎温度",
        key: "rr1tt",
        path: "customExt.rr1tt",
        unit: "°C", // 数据的单位
        type: "number",
        historyTable: {},
        historyGraph: {
          show: true,
        },
      },
      {
        name: "右后2轮胎温度",
        key: "rr2tt",
        path: "customExt.rr2tt",
        unit: "°C", // 数据的单位
        type: "number",
        historyTable: {},
        historyGraph: {
          show: true,
        },
      },
      {
        name: "气压1",
        key: "pressure1",
        path: "customExt.pressure1",
        unit: "kPa",
      },
      {
        name: "气压2",
        key: "pressure2",
        path: "customExt.pressure2",
        unit: "kPa",
      },
    ],
  },
  {
    name: "空调数据",
    key: "acdata",
    data: [
      {
        name: "空调开启模式",
        path: "customExt.airMode",
        key: "airMode",
        type: "string",
        format: val => AirmodeStatusI18N[val],
        historyTable: {
          defaultShow: true,
        },
      },
      {
        name: "空调设定温度",
        path: "customExt.airTemp",
        key: "airTemp",
        type: "number",
        unit: "°C",
        historyTable: {},
        historyGraph: {
          show: true,
        },
      },
      {
        name: "车厢内实际温度",
        path: "customExt.insideTemp",
        key: "insideTemp",
        type: "number",
        unit: "°C",
        historyTable: {
          defaultShow: true,
        },
        historyGraph: {
          show: true,
        },
      },
      {
        name: "环境温度",
        path: "customExt.outsideTemp",
        key: "outsideTemp",
        type: "number",
        unit: "°C",
        historyTable: {
          defaultShow: true,
        },
        historyGraph: {
          show: true,
        },
      },
    ],
  },
  {
    name: "电池数据",
    key: "betterydata",
    data: [
      {
        name: "电池总电压",
        key: "voltage",
        path: "overall.voltage",
        recordPath: "overall.voltage",
        unit: "V",
        type: "number",
        historyTable: {
          defaultShow: true,
          width: 120,
        },
        historyGraph: {
          show: true,
        },
      },
      {
        name: "电池总电流",
        key: "current",
        path: "overall.current",
        unit: "A",
        type: "number",
        historyTable: {
          defaultShow: true,
          width: 120,
        },
        historyGraph: {
          show: true,
        },
      },
      {
        name: "SOC",
        key: "soc",
        path: "overall.soc",
        format: val => {
          return Math.floor(Number(val) * 100);
        },
        unit: "%",
        type: "number",
        historyTable: {
          defaultShow: true,
        },
        historyGraph: {
          show: true,
          defaultShow: true,
        },
      },
      {
        name: "瞬间电耗",
        key: "instantPower",
        path: "customExt.instantPower",
        type: "number",
        historyTable: {},
        historyGraph: {
          show: true,
        },
      },
      {
        name: "单体电池最高温度",
        key: "maxNtc",
        path: "extreme.maxNtc",
        unit: "°C",
        type: "number",
        historyTable: {
          defaultShow: true,
        },
        historyGraph: {
          show: true,
        },
      },
      {
        name: "最高温度电芯编号",
        path: "extreme.maxNtcNo",
        key: "maxNtcNo",
        type: "string",
        historyTable: {},
      },
      {
        name: "单体电池最低温度",
        key: "minNtc",
        path: "extreme.minNtc",
        unit: "°C",
        type: "number",
        historyTable: {},
        historyGraph: {
          show: true,
        },
      },
      {
        name: "最低温度电芯编号",
        path: "extreme.minNtcNo",
        key: "minNtcNo",
        type: "string",
        historyTable: {},
      },
      {
        name: "单体电池最高电压",
        path: "extreme.maxVoltage",
        key: "maxVoltage",
        unit: "V",
        type: "number",
        historyTable: {},
        historyGraph: {
          show: true,
        },
      },
      {
        name: "最高电压电芯编号",
        path: "extreme.maxVoltageSingNo",
        key: "maxVoltageSingNo",
        type: "string",
        historyTable: {},
      },
      {
        name: "单体电池最低电压",
        path: "extreme.minVoltage",
        key: "minVoltage",
        unit: "V",
        type: "number",
        historyTable: {},
        historyGraph: {
          show: true,
        },
      },
      {
        name: "最低电压电芯编号",
        path: "extreme.minVoltageSingNo",
        key: "minVoltageSingNo",
        type: "string",
      },
      {
        name: "累计充电电能值",
        path: "customExt.totalCharge",
        key: "totalCharge",
        unit: "kWh",
        type: "number",
        historyTable: {},
        historyGraph: {
          show: true,
        },
      },
      {
        name: "累计放电电能值",
        path: "customExt.totalDischarge",
        key: "totalDischarge",
        unit: "kWh",
        type: "number",
        historyTable: {},
        historyGraph: {
          show: true,
        },
      },
      {
        name: "电池高压正极绝缘电阻",
        path: "customExt.bpiRes",
        key: "bpiRes",
        unit: "KΩ",
        type: "number",
        historyTable: {
          width: 120,
        },
        historyGraph: {
          show: true,
        },
      },
      {
        name: "电池高压负极绝缘电阻",
        path: "customExt.bniRes",
        key: "bniRes",
        unit: "KΩ",
        type: "number",
        historyTable: {
          width: 120,
        },
        historyGraph: {
          show: true,
        },
      },
    ],
  },

  {
    name: "电机数据",
    key: "motordata",
    data: [
      {
        name: "驱动电机状态",
        path: "motor.motors[0].status",
        key: "motor.status",
        format: val => MotorStatusI18N[val],
        type: "string",
        historyTable: { defaultShow: true },
      },
      {
        name: "驱动电机转速",
        path: "motor.motors[0].speed",
        key: "motor.speed",
        type: "number",
        historyTable: { defaultShow: true },
        historyGraph: {
          show: true,
        },
      },
      {
        name: "驱动电机温度",
        path: "motor.motors[0].temp",
        key: "motor.temp",
        unit: "°C",
        type: "number",
        historyTable: { defaultShow: true },
        historyGraph: {
          show: true,
        },
      },
      {
        name: "驱动电机转矩",
        path: "motor.motors[0].torque",
        key: "motor.torque",
        unit: "N·m",
        type: "number",
        historyTable: {},
        historyGraph: {
          show: true,
        },
      },
      {
        name: "电机控制器输入电流",
        path: "motor.motors[0].current",
        key: "motor.current",
        unit: "A",
        type: "number",
        historyTable: {
          width: 115,
        },
        historyGraph: {
          show: true,
        },
      },
      {
        name: "电机控制器输入电压",
        path: "motor.motors[0].voltage",
        key: "motor.voltage",
        unit: "V",
        type: "number",
        historyTable: {
          width: 115,
        },
        historyGraph: {
          show: true,
        },
      },
      {
        name: "电机控制器温度",
        path: "motor.motors[0].controlTemp",
        key: "motor.controlTemp",
        unit: "°C",
        type: "number",
        historyTable: {
          width: 110,
        },
        historyGraph: {
          show: true,
        },
      },
    ],
  },
  {
    name: "DC-DC数据",
    key: "dcdcdata",
    data: [
      {
        name: "DC-DC状态",
        path: "overall.dcStatus",
        key: "dcStatus",
        type: "string",
        historyTable: {
          width: 115,
        },
      },
      {
        name: "DC-DC输出电压",
        path: "customExt.dcov",
        key: "customExt.dcov",
        unit: "V",
        type: "number",
        historyTable: {
          width: 110,
        },
        historyGraph: {
          show: true,
        },
      },
      {
        name: "DC-DC输出电流",
        path: "customExt.dcoc",
        key: "customExt.dcoc",
        unit: "A",
        type: "number",
        historyTable: {
          width: 110,
        },
        historyGraph: {
          show: true,
        },
      },
      {
        name: "DC-DC散热器温度",
        path: "customExt.dcTemp",
        key: "customExt.dcTemp",
        unit: "°C",
        type: "number",
        historyTable: {
          width: 130,
        },
        historyGraph: {
          show: true,
        },
      },
      {
        name: "蓄电池电压值",
        path: "customExt.batteryVoltage",
        key: "customExt.batteryVoltage",
        unit: "V",
        type: "number",
        historyTable: {
          width: 80,
        },
        historyGraph: {
          show: true,
        },
      },
      {
        name: "DC-AC散热器温度",
        path: "customExt.acTemp",
        key: "customExt.acTemp",
        unit: "°C",
        type: "number",
        historyTable: {},
        historyGraph: {
          show: true,
        },
      },
    ],
  },
];

/**
 * Log server
 */
// socket open
export const LOG_SERVER_SOCKET_OPEN = "LOG_SERVER_SOCKET_OPEN";
// socket close
export const LOG_SERVER_SOCKET_CLOSE = "LOG_SERVER_SOCKET_CLOSE";
// server login
export const LOG_SERVER_LOGIN = "LOG_SERVER_LOGIN";
// 日志服务器连接状态改变
export const LOG_SERVER_CHANGE = "LOG_SERVER_CHANGE";
// subscribe vins
export const LOG_VINS_SUBSCRIBE = "LOG_VINS_SUBSCRIBE";
// rest vins
export const LOG_VINS_RESET = "LOG_VINS_RESET";
// log receive
export const LOG_RECEIVED = "LOG_RECEIVED";
// change filter keyword
export const LOG_SERVER_KEYWORD_CHANGE = "LOG_SERVER_KEYWORD_CHANGE";

export const TicketState = {
  CLOSED: "CLOSED",
  OPEN: "OPEN",
};

export const TicketStateI18N = {
  CLOSED: "已关闭",
  OPEN: "处理中",
};

export const TicketEvents = {
  CLOSE: "CLOSE",
  REOPEN: "REOPEN",
  STAGE: "STAGE",
  COMMENT: "COMMENT",
  BIND_ALERT: "BIND_ALERT",
  CREATE: "CREATE",
};

export const StageOrder = {
  待维修: 0,
  维修中: 1,
  已完成: 2,
};

export const ZIndex = {
  header: 1, // header
  searchTreeBox: 1000, // search tree box
  searchTreeCon: 100,
  monitorStats: 100, // monitor status
  monitorTop: 10, // monitor top middle
  monitorVehicleList: 100, // monitor top left vehicle list
};

export const AlertActions = {
  IGNORE: "IGNORE",
  TICKET: "TICKET",
};

/**
 * 处理方式
 */
export const AlertActionsI18N = {
  TICKET: "工单",
  IGNORE: "忽略报警",
};

export const AlertLevelColor = {
  3: "#F5222D",
  2: "#FF7A45",
  1: "#FAAD14",
};

export const AlertTypes = [
  "AC",
  "ADAS",
  "ATS",
  "DCDC",
  "整车",
  "电池",
  "电机",
  "气泵",
  "充电系统",
  "油泵",
];

export const AlertTypeFilter = ["ADAS"];

/**
 *Vehicle Schema
 */
export const VehicleStateSchema = {
  location: {
    title: "车辆位置",
    path: "location",
  },
  speed: {
    title: "车速",
    path: "speed",
    unit: "km/h",
  },
  mileage: {
    title: "里程",
    path: "mileage",
    unit: "km",
  },
  soc: {
    title: "SoC",
    path: "soc",
    unit: "%",
    format: (val, defaultVal = "--") => {
      if (isNil(val)) return defaultVal;
      return Math.floor(Number(val) * 100);
    },
  },
  batteryTemp: {
    title: "电池温度",
    path: "batteryTemp",
    unit: "℃",
  },
  voltage: {
    title: "总电压",
    path: "voltage",
    unit: "V",
  },
  current: {
    title: "总电流",
    path: "current",
    unit: "A",
  },
  airMode: {
    title: "空调状态",
    path: "airMode",
    format: (val, defaultVal = "--") => {
      return AirMode[val] || defaultVal;
    },
  },
  insideTemp: {
    title: "车内温度",
    path: "insideTemp",
    unit: "℃",
  },
  outsideTemp: {
    title: "车外温度",
    path: "outsideTemp",
    unit: "℃",
  },
  motorStatus: {
    title: "电机状态",
    path: "motors[0].status",
    format: (val, defaultVal = "--") => {
      return MotorStatusI18N[val] || defaultVal;
    },
  },
  motorSpeed: {
    title: "电机转速",
    path: "motors[0].speed",
    unit: "r/min",
  },
  motorTemp: {
    title: "电机温度",
    path: "motors[0].temp",
    unit: "℃",
  },
  shift: {
    title: "档位",
    path: "shift",
  },
  brake: {
    title: "制动踏板状态",
    path: "brake",
    unit: "%",
    format: (val, defaultVal = "--") => {
      if (isNil(val)) return defaultVal;
      return Math.floor(Number(val) * 100);
    },
  },
  resistance: {
    title: "整车绝缘",
    path: "resistance",
    unit: "Ω",
  },
};

/**
 *Vehicle Schema
 */
export const VehicleSchema = {
  location: {
    title: "车辆位置",
    path: "location",
  },
  speed: {
    title: "车速",
    path: "overall.speed",
    unit: "km/h",
  },
  mileage: {
    title: "里程",
    path: "overall.mileage",
    unit: "km",
  },
  soc: {
    title: "SoC",
    path: "overall.soc",
    unit: "%",
    format: (val, defaultVal = "--") => {
      if (isNil(val)) return defaultVal;
      return Math.floor(Number(val) * 100);
    },
  },
  batteryTemp: {
    title: "电池温度",
    path: "extreme.maxNtc",
    unit: "℃",
  },
  voltage: {
    title: "总电压",
    path: "overall.voltage",
    unit: "V",
  },
  current: {
    title: "总电流",
    path: "overall.current",
    unit: "A",
  },
  airMode: {
    title: "空调状态",
    path: "customExt.airMode",
    format: (val, defaultVal = "--") => {
      return AirMode[val] || defaultVal;
    },
  },
  insideTemp: {
    title: "车内温度",
    path: "customExt.insideTemp",
    unit: "℃",
  },
  outsideTemp: {
    title: "车外温度",
    path: "customExt.outsideTemp",
    unit: "℃",
  },
  motorStatus: {
    title: "电机状态",
    path: "motors[0].status",
    format: (val, defaultVal = "--") => {
      return MotorStatusI18N[val] || defaultVal;
    },
  },
  motorSpeed: {
    title: "电机转速",
    path: "motors[0].speed",
    unit: "r/min",
  },
  motorTemp: {
    title: "电机温度",
    path: "motors[0].temp",
    unit: "℃",
  },
  shift: {
    title: "档位",
    path: "overall.shift",
  },
  brake: {
    title: "制动踏板状态",
    path: "overall.brake",
    unit: "%",
    format: (val, defaultVal = "--") => {
      if (isNil(val)) return defaultVal;
      return Math.floor(Number(val) * 100);
    },
  },
  resistance: {
    title: "整车绝缘",
    path: "overall.resistance",
    unit: "Ω",
  },
};

export const RecordCommands = {
  REALTIME_REPORT: "REALTIME_REPORT",
  VEHICLE_LOGIN: "VEHICLE_LOGIN",
  REISSUE_REPORT: "REISSUE_REPORT",
  VEHICLE_LOGOUT: "VEHICLE_LOGOUT",
  PLATFORM_LOGIN: "PLATFORM_LOGIN",
  PLATFORM_LOGOUT: "PLATFORM_LOGOUT",
  HEARTBEAT: "HEARTBEAT",
  TIME: "TIME",
};

export const RecordCommandI18N = {
  REALTIME_REPORT: "实时信息上报",
  VEHICLE_LOGIN: "车辆登入",
  REISSUE_REPORT: "补发信息上报",
  VEHICLE_LOGOUT: "车辆登出",
  PLATFORM_LOGIN: "平台登入",
  PLATFORM_LOGOUT: "平台登出",
  HEARTBEAT: "心跳",
  TIME: "终端校时",
};

export const TerminalStatus = {
  NORMAL: "NORMAL",
  ABNORMAL: "ABNORMAL",
  UNKNOWN: "UNKNOWN",
};

export const TerminalStatusI18N = {
  NORMAL: "正常",
  ABNORMAL: "异常",
  UNKNOWN: "未知",
};

// 终端数据异常类型
export const TerminalDataException = {
  BATTERY_EXCEPTION: "TerminalBatteryExceptionI18N",
  MILEAGE_EXCEPTION: "TerminalMileageExceptionI18N",
  AIR_EXCEPTION: "TerminalAirExceptionI18N",
  TYRE_EXCEPTION: "TerminalTyreExceptionI18N",
};
export const TerminalDataExceptionI18N = {
  BATTERY_EXCEPTION: "电池数据异常",
  MILEAGE_EXCEPTION: "里程数据异常",
  AIR_EXCEPTION: "空调数据异常",
  TYRE_EXCEPTION: "轮胎数据异常",
};

export const TerminalBatteryException = {
  BATTERY_SOC_ZERO: "BATTERY_SOC_ZERO",
  BATTERY_TEMP_HIGH: "BATTERY_TEMP_HIGH",
};

export const TerminalMileageException = {
  MILEAGE_ZERO: "MILEAGE_ZERO", //  总里程为0
  MILEAGE_DECREASE: "MILEAGE_DECREASE", // 总里程减少
  MILEAGE_HIGH: "MILEAGE_HIGH", // 总里程过高
};

export const TerminalAirException = {
  AIR_TEMP_ZERO: "AIR_TEMP_ZERO",
  AIR_TEMP_LOW: "AIR_TEMP_LOW",
  AIR_ERROR_REFRIGERATION: "AIR_ERROR_REFRIGERATION",
  AIR_ERROR_HEATING: "AIR_ERROR_HEATING",
};

export const TerminalTyreException = {
  TYRE_TEMP_HIGH: "TYRE_TEMP_HIGH",
};

export const TerminalAirExceptionI18N = {
  AIR_TEMP_ZERO: "三个温度均为0",
  AIR_TEMP_LOW: "温度出现-40°C",
  AIR_ERROR_REFRIGERATION: "室外温度低于10°C，空调状态为制冷",
  AIR_ERROR_HEATING: "室外温度高于30°C，空调状态为制热",
};

export const TerminalTyreExceptionI18N = {
  TYRE_TEMP_HIGH: "轮胎温度等于205°C",
};

export const TerminalBatteryExceptionI18N = {
  BATTERY_SOC_ZERO: "SoC等于0%",
  BATTERY_TEMP_HIGH: "电池温度出现205℃",
};

export const TerminalMileageExceptionI18N = {
  MILEAGE_ZERO: "总里程为0",
  MILEAGE_DECREASE: "总里程减少",
  MILEAGE_HIGH: "总里程过高",
};

export const RepairShop = [
  {
    department: "杨高公司",
    shop: "成山路车间",
    address: "邹平路191号",
    position: [121.51622, 31.171556],
  },
  {
    department: "杨高公司",
    shop: "高行车间",
    address: "东靖路2476号",
    position: [121.616292, 31.292872],
  },
  {
    department: "杨高公司",
    shop: "周浦车间",
    address: "周东南路1237号",
    position: [121.59269, 31.087689],
  },
  {
    department: "金高四分公司",
    shop: "曹路车间",
    address: "川安路189号",
    position: [121.647967, 31.28321],
  },
  {
    department: "金高三分公司",
    shop: "金高路车间",
    address: "金高路1500号",
    position: [121.609123, 31.275099],
  },
  {
    department: "金高一分公司",
    shop: "曹路车间",
    address: "川安路189号",
    position: [121.647645, 31.283041],
  },
  {
    department: "金高二分公司",
    shop: "金桥路车间",
    address: "金桥路2270号",
    position: [121.600945, 31.238365],
  },
  {
    department: "上南公司",
    shop: "成山路车间",
    address: "邹平路191号",
    position: [121.517215, 31.172351],
  },
  {
    department: "上南公司",
    shop: "高科西路车间",
    address: "高科西路1758号",
    position: [121.527768, 31.186871],
  },
  {
    department: "上南公司",
    shop: "高青路车间",
    address: "杨高南路4188号",
    position: [121.523314, 31.157375],
  },
  {
    department: "南汇公司",
    shop: "下盐路车间",
    address: "下盐路7300号",
    position: [121.774245, 31.071918],
  },
  {
    department: "南汇五分公司",
    shop: "川沙车间",
    address: "妙境路1000号",
    position: [121.692509, 31.183058],
  },
];

// 新能源车充电分时电价时段
export const ChargePeriodI18N = {
  HIGH: "峰",
  LOW: "谷",
  NORMAL: "平",
};

// 停车场信息
export const ParkData = [
  {
    id: "1",
    department: "杨高公司",
    name: "金桥停车场",
    address: "浦东大道2620号",
    position: [121.569604, 31.264426],
  },
  {
    id: "2",
    department: "杨高公司",
    name: "  成山路停车场",
    address: "邹平路191号",
    position: [121.51622, 31.171556],
  },
  {
    id: "3",
    department: "杨高公司",
    name: "东靖路停车场",
    address: "东靖路2476号",
    position: [121.616292, 31.292872],
  },
  {
    id: "4",
    department: "杨高公司",
    name: "周东南路停车场",
    address: "周东南路瑞和路",
    position: [121.59269, 31.087689],
  },
  {
    id: "5",
    department: "杨高公司",
    name: "曹路停车场",
    address: "川安路189号",
    position: [121.647967, 31.28321],
  },
  {
    id: "6",
    department: "杨高公司",
    name: "金新路停车场",
    address: "金新路58号",
    position: [121.598975, 31.252451],
  },
  {
    id: "7",
    department: "杨高公司",
    name: "秀沿路停车场",
    address: "秀沿路3075号",
    position: [121.6, 31.136444],
  },
  {
    id: "8",
    department: "杨高公司",
    name: "周祝公路停车场",
    address: "周祝公路1324号",
    position: [121.646228, 31.107122],
  },
  {
    id: "9",
    department: "金高公司",
    name: "妙境路停车场",
    address: "妙境路1000号",
    position: [121.692708, 31.182851],
  },
  {
    id: "10",
    department: "	金高公司",
    name: "金高路停车场",
    address: "金高路1500号	",
    position: [121.609123, 31.275099],
  },
  {
    id: "11",
    department: "金高公司",
    name: "	川安路停车场",
    address: "川安路189号",
    position: [121.647645, 31.283041],
  },
  {
    id: "12",
    department: "金高公司",
    name: "南公交枢纽站停车场",
    address: "申迪南路1000号",
    position: [121.668643, 31.132243],
  },

  {
    id: "14",
    department: "上南公司",
    name: "高科西路充电站",
    address: "高科西路1758号",
    position: [121.527768, 31.186871],
  },
  {
    id: "15",
    department: "上南公司",
    name: "徐浦大桥充电站",
    address: "环南一大道",
    position: [121.47445, 31.129175],
  },
  {
    id: "16",
    department: "上南公司",
    name: "上南路充电站	",
    address: " 林海公路上南路",
    position: [121.542262, 31.120477],
  },
  {
    id: "17",
    department: "南汇公司",
    name: "迪士尼充电场	",
    address: " 浦东新区申迪西路1000号",
    position: [121.659471, 31.139317],
  },
  {
    id: "18",
    department: "南汇公司",
    name: "共享区充电场	",
    address: " 浦东新区沪城环路699号",
    position: [121.900661, 30.879954],
  },
  {
    id: "19",
    department: "南汇公司",
    name: "古棕路充电场	",
    address: " 浦东新区B5临港新城古棕路400号",
    position: [121.911393, 30.87712],
  },
  {
    id: "20",
    department: "南汇公司",
    name: "秋雨路充电场 ",
    address: "  浦东新区秋雨路696号",
    position: [121.816779, 30.908519],
  },
  {
    id: "21",
    department: "南汇公司",
    name: "临港大道充电场",
    address: "浦东新区临港大道1360号",
    position: [121.91292, 30.922574],
  },
  {
    id: "22",
    department: "南汇公司",
    name: "车站路充电场",
    address: " 浦东新区车站路12号",
    position: [121.76397, 31.046565],
  },
];
