import {
  ListStationsCountableField,
  StationType,
  OrderGroupField,
  OrderCountableField,
} from "@36node-mekong/sdk-ts";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { get, round } from "lodash";
import { Table, Card, Button, DatePicker } from "antd";
import { Link } from "react-router-dom";
import ReactEcharts from "echarts-for-react";
import moment from "moment";

import { tableStateToMekongQuery } from "../../lib";
import { stationSelectors } from "../../selectors";
import { pileActions } from "../../actions/pile";
import { makeMekongApiToTableSelector } from "../../selectors/api-to-table";
import StationEditor from "../../components/station/editor";
import { SourceNameI18N } from "../../constants";

const defaultDate = moment().subtract(1, "day");

class StationListPage extends PureComponent {
  state = {
    editStation: null,
    date: moment()
      .subtract(1, "day")
      .format("YYYY-MM-DD"),
  };

  componentDidMount() {
    this.listStations();
    this.fetchChargeAggregationPerHour();
  }

  listStations = (pagination = {}, filters = {}, sort = {}) => {
    const { query } = this.props;
    const tableQuery = tableStateToMekongQuery(pagination, filters, sort);

    this.props.dispatch(
      pileActions.listPileStations.request(
        {
          _count: [
            ListStationsCountableField.CONNECTOR,
            ListStationsCountableField.VEHICLE,
          ],
          type: StationType.CHARGER,
          ...query,
          ...tableQuery,
        },
        {}
      )
    );
  };

  fetchChargeAggregationPerHour = (date = defaultDate) => {
    const start = date
      .clone()
      .hour(8)
      .startOf("hour");
    let end = start
      .clone()
      .add(1, "d")
      .subtract(1, "ms");
    if (end > moment()) {
      end = moment().endOf("hour");
    }

    this.props.dispatch(
      pileActions.chargeAggregatePerHour.request(
        {
          at_gte: start.toISOString(),
          at_lte: end.toISOString(),
          _group: [OrderGroupField.AT_HOUR, OrderGroupField.AT_DAY],
          _count: [OrderCountableField.ELEC, OrderCountableField.VEHICLE],
        },
        {}
      )
    );
  };

  getStationChargerOption = () => {
    let xData = [],
      y1Data = [],
      y2Data = [];
    const { chartResult } = this.props;
    const countPerHour = chartResult.result;
    if (countPerHour) {
      for (let i = 0; i < 24; ++i) {
        const h = (i + 8 + 1) % 24;
        xData.push(h);
        y1Data.push(0);
        y2Data.push(0);
      }
      countPerHour.forEach(item => {
        //hour 0-23
        if (item.at.hour < 8) {
          y1Data[item.at.hour + 16] = parseFloat(item.elec.toFixed(2));
          y2Data[item.at.hour + 16] = parseFloat(item.count.toFixed(2));
        } else {
          y1Data[item.at.hour - 8] = parseFloat(item.elec.toFixed(2));
          y2Data[item.at.hour - 8] = parseFloat(item.count.toFixed(2));
        }
      });
    }
    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#999",
          },
        },
      },
      legend: {
        data: ["充电量", "充电车次"],
      },
      xAxis: [
        {
          type: "category",
          data: xData,
          boundaryGap: false,
        },
      ],
      yAxis: [
        {
          type: "value",
          name: "充电量(kWh)",
          min: 0,
          nameTextStyle: {
            align: "center",
          },
        },
        {
          type: "value",
          name: "充电车次",
          min: 0,
          nameTextStyle: {
            align: "center",
          },
        },
      ],
      series: [
        {
          name: "充电量",
          type: "line",
          areaStyle: { color: "#63B5F8", opacity: 0.7 },
          data: y1Data,
          itemStyle: {
            color: "#5878CA",
          },
        },
        {
          name: "充电车次",
          type: "line",
          yAxisIndex: 1,
          data: y2Data,
          itemStyle: {
            color: "#F49B44",
          },
        },
      ],
      grid: {
        height: 210,
        left: 100,
        right: 100,
      },
    };
  };

  handleOpenEditor = station => {
    this.setState({ editStation: station });
  };

  handleCloseEditor = () => {
    this.setState({ editStation: null });
  };

  handleUpdateStation = (stationId, updateDoc) => {
    this.props.dispatch(
      pileActions.updatePileStation.request(
        {
          station: stationId,
          body: updateDoc,
        },
        {}
      )
    );
  };

  handleDeleteStation = stationId => {
    this.props.dispatch(
      pileActions.deletePileStation.request({ station: stationId }, {})
    );
  };

  handleNaviToStationOrder = stationId => {
    this.props.history.push(`/station-order/${stationId}`);
  };

  handleDateChange = date => {
    this.fetchChargeAggregationPerHour(date);
  };

  disabledDate = current => {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  };

  renderDateSelector = () => {
    return (
      <DatePicker
        onChange={this.handleDateChange}
        defaultValue={defaultDate}
        disabledDate={this.disabledDate}
      />
    );
  };

  render() {
    const {
      docs = [],
      loading,
      total = 0,
      pagination,
      totalElec,
      totalCount,
    } = this.props;
    const { editStation } = this.state;

    const finalColumns = [
      ...TableColumns,
      {
        title: "操作",
        key: "operator",
        dataIndex: "id",
        disableImport: true,
        disableExport: true,
        render: (val, record) => {
          return (
            <>
              <Button
                type="link"
                style={{ padding: 0 }}
                onClick={() => this.handleOpenEditor(record)}
              >
                编辑
              </Button>
              &nbsp;&nbsp;
              <Button
                type="link"
                style={{ padding: 0 }}
                onClick={() => this.handleNaviToStationOrder(val)}
              >
                充电记录
              </Button>
            </>
          );
        },
      },
    ];

    const extra = this.renderDateSelector();

    return (
      <>
        <Card
          title={
            <>
              充电状态统计
              <small style={{ marginLeft: 5 }}>
                充电量: {totalElec}kWh 充电车次: {totalCount}次
              </small>
            </>
          }
          extra={extra}
        >
          <ReactEcharts option={this.getStationChargerOption()} />
        </Card>
        <Card
          style={{ marginTop: "20px" }}
          bordered={false}
          title={`共有${total}个充电站`}
        >
          <Table
            rowKey="id"
            loading={loading}
            onChange={this.listStations}
            dataSource={docs}
            columns={finalColumns}
            pagination={pagination}
            scroll={{ x: "max-content" }}
          />
          <StationEditor
            onClose={this.handleCloseEditor}
            station={editStation}
            onSubmit={this.handleUpdateStation}
            onDeleted={this.handleDeleteStation}
          />
        </Card>
      </>
    );
  }
}

export default connect(state => {
  const selector = makeMekongApiToTableSelector(
    stationSelectors.listPileStations
  );
  const tableState = selector(state);

  const chartResult = stationSelectors.aggregateOrdersPerDay(state);

  const countPerHour = chartResult.result || [];
  const totalElec = countPerHour.reduce((acc, cur) => {
    return acc + cur.elec;
  }, 0);
  const totalCount = countPerHour.reduce((acc, cur) => {
    return acc + cur.count;
  }, 0);

  return {
    ...tableState,
    chartResult,
    totalElec: round(totalElec, 2),
    totalCount,
  };
})(StationListPage);

export const TableColumns = [
  {
    title: "充电站",
    dataIndex: "name",
    key: "name",
    render: (val, record) => (
      <Link
        to={{
          pathname: `/monitor-table/station/${record.id}/connector`,
          state: { from: "/station/list" },
        }}
      >
        {val}
      </Link>
    ),
  },
  {
    title: "服务商平台",
    dataIndex: "source",
    key: "source",
    render: val => val.map(v => SourceNameI18N[v]).join(", "),
  },
  { title: "充电站地址", dataIndex: "address", key: "address" },
  {
    title: "服务公司",
    dataIndex: "ns",
    key: "ns",
    render: ns => ns && ns.name,
    exportFormat: ns => ns && ns.name,
  },
  {
    title: "服务车辆数",
    dataIndex: "vehicleNum",
    key: "vehicleNum",
  },
  {
    title: "充电枪总数",
    dataIndex: "connectorNum.total",
    key: "connectorNum.total",
  },
  {
    title: "充电",
    dataIndex: "connectorNum",
    key: "connectorNum.charging",
    render: obj => get(obj, "OCCUPIED_CHARGING"),
  },
  {
    title: "占用",
    dataIndex: "connectorNum",
    key: "connectorNum.occupied",
    render: obj =>
      get(obj, "OCCUPIED_NOT_CHARGING") + get(obj, "OCCUPIED_ORDERED"),
  },
  {
    title: "空闲",
    dataIndex: "connectorNum.IDLE",
    key: "connectorNum.IDLE",
  },
  {
    title: "故障",
    dataIndex: "connectorNum.ERROR",
    key: "connectorNum.ERROR",
  },
  {
    title: "离线",
    dataIndex: "connectorNum.OFFLINE",
    key: "connectorNum.OFFLINE",
  },
];
