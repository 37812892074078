/**
 * Global selectors
 */

import { createApiSelector } from "@36node/redux-api";
import makeLinesByNsSelector from "./lines-by-ns";
import * as CS from "../constants";
import { createSelector } from "reselect";
import {
  lineSchema,
  producerSchema,
  namespacesSchema,
  alertSchema,
  mekongVehicleSchema,
} from "../schemas";
import { createAssignSelector } from "@36node/redux-ui";
import { createToggleSelector } from "@36node/redux-ui";
import { get, orderBy } from "lodash";
import { getPathname } from "../lib";
import listToTree from "../lib/list-to-tree";
import { ROOT_NAMESPACE } from "../config";

export const entitiesSelector = state => state.entities || {};

const listNamespaces = createApiSelector(CS.NS.GLOBAL.LIST_NAMESPACES, [
  namespacesSchema,
]);

const namespaces = createSelector(
  listNamespaces,
  listResp => {
    const ret = orderBy(listResp.result || [], "key");
    return ret.map(n => ({ ...n, pathname: getPathname(n) }));
  }
);

const namespaceMap = createSelector(
  namespaces,
  ns => ns.reduce((map, cur) => ({ ...map, [cur.id]: cur }), {})
);

const namespacesTree = createSelector(
  namespaces,
  (namespaces = []) => {
    const withNs = namespaces.map(n => {
      return {
        ...n,
        title: n.name,
        key: n.id,
        value: n.id,
      };
    });

    return listToTree(withNs);
  }
);

/**
 * 不包括root的 ns tree selector
 */
const namespacesTreeNoRoot = createSelector(
  namespacesTree,
  (nsTree = []) => {
    const result = (nsTree[0] && nsTree[0].children) || nsTree;
    return result;
  }
);

const listLines = createApiSelector(CS.NS.GLOBAL.LIST_LINES, [lineSchema]);
const lineByNs = makeLinesByNsSelector(listLines);
const session = createApiSelector("session");
const captcha = createApiSelector("captcha");

const lines = createSelector(
  listLines,
  listResp => {
    return listResp.result || [];
  }
);

const listAlerts = createApiSelector(CS.NS.GLOBAL.LIST_ALERTS, [alertSchema]);

const alerts = createSelector(
  listAlerts,
  listResp => {
    return listResp.result || [];
  }
);

/**
 * 选择当前登录的用户
 */
const loginUser = createSelector(
  session,
  namespaces,
  (session, namespaces) => {
    let user = get(session, "result.user");

    if (typeof user !== "object") {
      user = get(session, "result.profile");
    }

    if (!user) user = {};

    if (user.ns) {
      if (namespaces.length > 0) {
        const ns = namespaces.find(ns => ns.id === user.ns);
        user.departments = [getPathname(ns)];
      }
    }
    return user;
  }
);

const globalSelectors = {
  listVehicles: createApiSelector(CS.NS.GLOBAL.LIST_VEHICLES, [
    mekongVehicleSchema,
  ]),
  keywordAssignSelector: createAssignSelector(CS.NS.GLOBAL.CHANGE_TREE_KEYWORD),
  toggleSelector: createToggleSelector(CS.NS.GLOBAL.TOGGLE_FOCUS_SEARCH),
  session,
  captcha,
  loginUser,
  listProducers: createApiSelector(CS.NS.GLOBAL.LIST_PRODUCERS, [
    producerSchema,
  ]),
  lineByNs,
  listLines,
  lines,
  alerts,
  weather: createApiSelector(CS.NS.GLOBAL.GET_WEATHER),
  entities: entitiesSelector,
  namespaces,
  namespaceMap,
  namespacesTree,
  namespacesTreeNoRoot,
  listNamespaces,
  getAlertSummary: createApiSelector(CS.NS.GLOBAL.GET_ALERT_SUMMARY),
  getStationAlertSummary: createApiSelector(
    CS.NS.GLOBAL.GET_STATION_ALERT_SUMMARY
  ),
  loginCountDown: createAssignSelector(CS.NS.GLOBAL.LOGIN_COUNT_DOWN),
};

export default globalSelectors;
